<template>
    <teleport to="head">
        <title>{{ txt[prefs.lang].checkout }} – PLANTA</title>
    </teleport>

    <h1 v-if="!props.id && step < 3">{{ txt[prefs.lang].checkout }}</h1>
    <h1 v-else-if="step === 3 && subRes && subRes.id"></h1>
    <h1 v-else-if="props.id">{{ txt[prefs.lang].order_editing }} <span v-if="user">#{{ props.id }}</span></h1>

    <template v-if="user && me">
        <h3>Клиент: {{ me.firstname }}</h3>

        Контакт:
        <el-select v-if="user && me" v-model="contact" @change.native="switchContact(contact.value)" filterable>
            <el-option v-for="c in me.contacts" :value=c.id
               :label="c.firstname+' '+c.lastname+' 0'+c.phone.number"></el-option>
        </el-select>
        <el-button @click="cleanFd">Очистити</el-button>

    </template>


    <el-form v-if="step === 1" :rules="rules" :model="fd" label-width="auto" inline class="checkout_form">

        <el-form-item v-if="subRes && subRes.error">
            <el-alert
                style="line-height: 1.5em !important;"
                :title=txt[prefs.lang].wrong
                type="error"
                :description=txt[prefs.lang].errors[subRes.error]
                show-icon
                :closable="false">
            </el-alert>
        </el-form-item>

        <el-form-item :label=txt[prefs.lang].firstname prop="firstname">
            <el-input v-model="fd.firstname" :placeholder=txt[prefs.lang].firstname clearable></el-input>
        </el-form-item>
        <el-form-item :label=txt[prefs.lang].lastname prop="lastname">
            <el-input v-model="fd.lastname" :placeholder=txt[prefs.lang].lastname clearable></el-input>
        </el-form-item>
        <el-form-item :label=txt[prefs.lang].middlename prop="middlename">
            <el-input v-model="fd.middlename" :placeholder="txt[prefs.lang].mid_i" clearable></el-input>
        </el-form-item>
        <el-form-item :label=txt[prefs.lang].phone prop="phone">
            <el-input v-model="fd.phone" :placeholder=txt[prefs.lang].phone style="max-width: 320px" @keydown="cart.preventor($event)" clearable>
                <template #prepend>+380</template>
            </el-input>
        </el-form-item>
<!--        <el-form-item :label=txt[prefs.lang]."E-mail">-->
<!--            <el-input v-model="fd.email" :placeholder=txt[prefs.lang]."email" :rules="[-->
<!--      { required: true, message: 'Please input email address', trigger: 'blur' },-->
<!--      { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }-->
<!--    ]" clearable></el-input>-->
<!--        </el-form-item>-->

        <el-form-item :label=txt[prefs.lang].delivery.t prop="delivery">
            <el-select v-model="fd.delivery" :placeholder=txt[prefs.lang].delivery.t @change.native="fd.payment_method = null">
                <el-option value="1" :label=txt[prefs.lang].delivery[1]></el-option>
                <el-option value="2" :label=txt[prefs.lang].delivery[2]></el-option>
                <el-option value="0" :label=txt[prefs.lang].delivery[0]></el-option>
            </el-select>
        </el-form-item>


        <!-- Ukrposhta -->

        <!--        <label for="city">Город</label><br>-->
<!--        <input type="text" name="city" @keyup="xreq($event.target.value)"><br>-->
<!--            <datalist id="cityname">-->
<!--                <option value="Boston">-->
<!--                <option value="Cambridge">-->
<!--            </datalist>-->
<!--         v-if="list && list.data && list.data[0].Addresses[0].MainDescription" -->
<!--         @keyup="xreq($event.target.value)" -->
<!--         ('+i.Warehouses+') -->
<!--                            :label="i.SettlementTypeCode+' '+i.MainDescription+' ('+i.Area+' обл., '+i.Region+' район) | '+i.Warehouses">
 -->
        <el-form-item v-if="fd.delivery > 0" :label=txt[prefs.lang].delivery.type.t>
            <el-radio-group v-model="fd.delivery_type">
<!--                @change.native="getWH"-->
                <el-radio label=1>{{ txt[prefs.lang].delivery.type[1] }}</el-radio>
                <el-radio label=2>{{ txt[prefs.lang].delivery.type[2] }}</el-radio>
            </el-radio-group>
        </el-form-item>


        <el-form-item v-if="fd.delivery > 0 && fd.delivery_type == 2 && cart.total() >= settings.free_shipping_from && (me && me.group.id < 10 || !me)">
            <el-alert
                :title=txt[prefs.lang].delivery.not_free
                type="warning"
                :closable=false
                show-icon>
            </el-alert>
        </el-form-item>


        <el-form-item :class="{ hidden: fd.delivery < 1 }" :label=txt[prefs.lang].delivery.settlement>
            <el-select filterable clearable remote v-model="fd.np_city.Ref" @change.native="onCityChange" id="city" :placeholder=txt[prefs.lang].delivery.settlement>
        <!--    @change.native="getWH" :remote-method="getCities" -->
                <el-option
                    v-for="i in cities"
                    :value="i.Ref"
                    :label="i.Present">
                </el-option>
            </el-select>
        </el-form-item>

<!--        <el-form-item v-if="fd.delivery == 1 && fd.delivery_type == 1 && whs" :label=txt[prefs.lang]."Отделение">-->
<!--            <el-select filterable v-model="fd.np_wh_ref" :placeholder=txt[prefs.lang]."Отделение">-->
<!--                <el-option-->
<!--                    v-for="i in whs"-->
<!--                    :value="i.Ref"-->
<!--                    :label="i['Description'+ttl]+', '+i['CityDescription'+ttl]">-->
<!--                </el-option>-->
<!--            </el-select>-->
<!--        </el-form-item>-->

        <el-form-item v-if="fd.delivery == 1 && fd.delivery_type == 1 && !isSrcNp" label="№">
            <el-select filterable clearable remote v-model="fd.np_wh.Ref" :remote-method="setWhTimer" @change.native="onWhChange" :placeholder=txt[prefs.lang].delivery.wh>
                <el-option
                    v-for="i in whs"
                    :value="i.Ref"
                    :label="i[ttl]">
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item v-if="fd.delivery == 1 && fd.delivery_type == 1 && isSrcNp" label="№">
            <el-select filterable clearable v-model="fd.np_wh.Ref" :placeholder=txt[prefs.lang].delivery.wh @change.native="onWhChange">
                <el-option
                    v-for="i in whs"
                    :value="i.Ref"
                    :label="i[ttl]">
                </el-option>
            </el-select>
        </el-form-item>

        <!--        <el-form-item v-if="fd.delivery == 1 && fd.delivery_type == 2" :label=txt[prefs.lang]."Адрес">-->
<!--            <el-input v-model="fd.np_addr" :placeholder=txt[prefs.lang]."Адрес"></el-input>-->
<!--        </el-form-item>-->

        <el-form-item v-if="fd.delivery == 2 && fd.delivery_type == 1" :label=txt[prefs.lang].delivery.wh_ukp>
            <el-input v-model="fd.ukp_wh" :placeholder=txt[prefs.lang].delivery.wh_ukp></el-input>
        </el-form-item>

        <el-form-item v-if="(fd.delivery == 1 && fd.delivery_type == 2) || (fd.delivery == 2 && fd.delivery_type == 2)" :label=txt[prefs.lang].delivery.address>
            <el-input v-model="fd.address" :placeholder=txt[prefs.lang].delivery.address></el-input>
        </el-form-item>


        <el-form-item :label=txt[prefs.lang].payment.t prop="payment_method">
            <el-select v-model="fd.payment_method" :placeholder=txt[prefs.lang].payment.t>
                <el-option value="5" :label=txt[prefs.lang].payment[5]></el-option>
<!--                <el-option value="1" :label=txt[prefs.lang].payment[1]></el-option>-->
<!--                <el-option value="2" :label=txt[prefs.lang].payment[2]></el-option>-->
                <el-option v-if="fd.delivery == 0" value="3" :label=txt[prefs.lang].payment[3]></el-option>
                <el-option v-if="fd.delivery >= 1" value="4" :label=txt[prefs.lang].payment[4]></el-option>
            </el-select>
        </el-form-item>

<!--        <el-form-item>-->
            <el-alert
                v-if="fd.payment_method == 4"
                :title=txt[prefs.lang].payment.r
                type="warning"
                style="margin-bottom: 15px;"
                :closable=true
                show-icon>
            </el-alert>
<!--        </el-form-item>-->

        <!--        <p>{{ me.socials }}</p>-->

<!--        <el-form-item v-if="fd.commethod >= 5" :label=txt[prefs.lang].commethod[fd.commethod]>-->
<!--            <el-input v-model="fd.comcontact" :placeholder=txt[prefs.lang].commethod[fd.commethod]></el-input>-->
<!--        </el-form-item>-->

        <el-form-item :label=txt[prefs.lang].comment>
            <el-input type="textarea" v-model="fd.comment" :placeholder=txt[prefs.lang].comment :rows="2" style="width: 320px"></el-input>
        </el-form-item>

        <div class="centered">
            <p class="hidden-xs-only">
                <el-tooltip :content=txt[prefs.lang].needconf_d>
                    <el-checkbox v-model="fd.needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>
                </el-tooltip>
            </p>
            <p class="hidden-sm-and-up">
                <el-checkbox v-model="fd.needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>
                <br>({{ txt[prefs.lang].needconf_d }})
            </p>
        </div>

        <el-form-item v-if="user" label="Звідки">
            <el-select v-model="fd.source_id" :placeholder=txt[prefs.lang].order_source>
                <el-option v-for="s in sources" :value=s.id :label=s.uk></el-option>
            </el-select>
        </el-form-item>


      <el-form-item v-if="me">
        <el-alert style="line-height: 1.5em !important;"
              type="primary"
              :description="'💡 '+txt[prefs.lang].change_commethod+' '+txt[prefs.lang].can_changecom"
              show-icon>
        </el-alert>
      </el-form-item>

      <el-form-item class="centered">
            <router-link v-if="props.id" :to="'/order/'+props.id"><el-button @click="cancelEditing" round>{{ txt[prefs.lang].cancel_editing }}</el-button></router-link>&nbsp;

            <el-button type="success" round
                :disabled="!isFilled || placeOrderLoading"
                @click="onSubmit(2)">{{ props.id ? txt[prefs.lang].next_step : txt[prefs.lang].to_checkout }}</el-button>
<!--            <el-input type="submit" value="Submit">Submit</el-input>-->

        </el-form-item>

    </el-form>



    <template v-else-if="step === 2">

        <table>
            <tr><td>{{ txt[prefs.lang].firstname }}</td>
                <td>{{ fd.lastname }} {{ fd.firstname }} <template v-if="fd.middlename">{{ fd.middlename }}</template></td>
            </tr>
            <tr><td>{{ txt[prefs.lang].phone }}</td><td>+380{{ fd.phone }}</td></tr>
            <tr><td>{{ txt[prefs.lang].payment.t }}</td><td>{{ txt[prefs.lang].payment[fd.payment_method] }}</td></tr>
            <tr><td>{{ txt[prefs.lang].delivery.t }}</td><td>{{ txt[prefs.lang].delivery[fd.delivery] }}</td></tr>
            <tr v-if="fd.delivery > 0"><td>{{ txt[prefs.lang].delivery.type.t }}</td><td>{{ txt[prefs.lang].delivery.type[fd.delivery_type] }}</td></tr>
            <tr v-if="fd.delivery > 0"><td>{{ txt[prefs.lang].delivery.settlement }}</td><td>{{ fd.np_city.Present }}</td></tr>
            <tr v-if="fd.delivery == 1 && fd.delivery_type == 1"><td>{{ txt[prefs.lang].delivery.wh }}</td><td>{{ fd.np_wh[ttl] }}</td></tr>
            <tr v-else-if="fd.delivery == 2 && fd.delivery_type == 1"><td>{{ txt[prefs.lang].delivery.wh }}</td><td>{{ fd.ukp_wh }}</td></tr>
            <tr v-else-if="fd.delivery_type == 2"><td>{{ txt[prefs.lang].delivery.address }}</td><td>{{ fd.address }}</td></tr>
            <tr v-if="fd.comment"><td>{{ txt[prefs.lang].comment }}</td><td>{{ fd.comment }}</td></tr>
<!--            <tr><td>{{ txt[prefs.lang].commethod.t }}</td><td>{{ txt[prefs.lang].commethod[fd.commethod].t }}<template v-if="fd.comcontact">: {{ fd.comcontact }}</template></td></tr>-->
        </table>

        <br><br>
        <table v-if="!props.id">
            <template v-for="item in cart.items">
                <CheckoutItem v-if="item && item.q > 0" :item="item"></CheckoutItem>
            </template>
            <CartDiscounts />
            <tr style="font-weight: bold;">
                <td>{{ txt[prefs.lang].total }}</td>
                <td colspan="4">{{ cart.q() }} {{ txt[prefs.lang].pcs }}</td>
                <td>
                    <span v-if="cart.total() !== cart.pre_discount_total()" class="crossed">{{ cart.pre_discount_total() }}₴<br></span>
                    {{ Math.round(cart.total()*100)/100 }}₴
                </td>
                <td></td>
            </tr>

        </table>

        <el-form class="centered">
            <router-link v-if="!props.id" to="/cart">
                <el-button type="default" plain round>{{ txt[prefs.lang].edit_order }}</el-button>
            </router-link>&nbsp;
            <el-button type="default" plain round
                :disabled="!isFilled || placeOrderLoading"
                @click="onSubmit(1)">{{ txt[prefs.lang].edit }}
            </el-button>
            <el-button v-if="!props.id" type="success" round
               :disabled="!isFilled || placeOrderLoading"
                @click="onSubmit(3)">{{ txt[prefs.lang].confirm_order }}
            </el-button>
            <el-button v-else type="success" round
               :disabled="!isFilled || placeOrderLoading"
               @click="onSubmit(4)">{{ txt[prefs.lang].save }}
            </el-button>
        </el-form>

    </template>



<!--    <template v-if="subRes && subRes.hash">-->

<!--        <div v-if="subRes && subRes.hash" class="centered narrow distanced" style="max-width: 700px;">-->

<!--            <h1>{{ txt[prefs.lang].thanks }}</h1>-->
<!--            <h3>{{ txt[prefs.lang].your_order }} {{ txt[prefs.lang].placed }}</h3>-->

<!--            <template v-if="subRes.card">-->

<!--                <p v-if="!whaitavail">{{ txt[prefs.lang].all_avail }}</p>-->
<!--                <p v-if="!whaitavail">{{ txt[prefs.lang].will_ship }}</p>-->

<!--                <p class="grey">-->
<!--                    <b>IBAN:</b> {{ subRes.card[0] }}-->
<!--                    &nbsp;<el-button plain round icon="el-icon-document-copy" @click="copy(subRes.card[0])" size="mini"> {{ txt[prefs.lang].copy }}</el-button>-->
<!--                    <br>-->
<!--                    <b>{{ txt[prefs.lang].payment.i }}</b> {{ txt.uk.order }} {{ txt.uk.from }} {{ subRes.placed }}-->
<!--                    <el-button plain round icon="el-icon-document-copy" @click="copy(txt.uk.order +' '+ txt.uk.from+' '+subRes.placed)" size="mini"></el-button>-->
<!--                    <br>-->
<!--                    <b>{{ txt[prefs.lang].to_pay }}:</b> {{ subRes.sum_to_pay }} грн<br>-->
<!--                    <br>-->
<!--                    <el-button plain round size="mini" icon="el-icon-document"-->
<!--                               @click="requisites = subRes.card[1] + '\n' + txt[prefs.lang].payment.i+' '+txt.uk.order +' '+ txt.uk.from+' '+subRes.placed+'\n'+txt[prefs.lang].to_pay+': '+subRes.sum_to_pay+' грн'">{{ txt[prefs.lang].payment.full }}</el-button>-->

<!--                    <br><br>💡 {{ txt[prefs.lang].mono_adv }}-->

<!--                </p>-->

<!--            </template>-->

<!--            <p v-if="subRes.pick_up">{{ txt[prefs.lang].pick_up1 }} {{ subRes.pick_up }} {{ txt[prefs.lang].pick_up2 }}</p>-->
<!--            &lt;!&ndash;            txt[prefs.lang].working_hours_p         &ndash;&gt;-->
<!--            <p v-if="subRes.pick_up" v-html="settings['working_hours_'+prefs.lang].split('&lt;!&ndash;SEP&ndash;&gt;')[1]"></p>-->

<!--            &lt;!&ndash;            <p class="hidden-xs-only">&ndash;&gt;-->
<!--            &lt;!&ndash;                <el-tooltip :content=txt[prefs.lang].needconf_d placement="top">&ndash;&gt;-->
<!--            &lt;!&ndash;                    <el-checkbox v-model="needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>&ndash;&gt;-->
<!--            &lt;!&ndash;                </el-tooltip>&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->
<!--            &lt;!&ndash;            <p class="hidden-sm-and-up">&ndash;&gt;-->
<!--            &lt;!&ndash;                <el-checkbox v-model="needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>&ndash;&gt;-->
<!--            &lt;!&ndash;                <br>({{ txt[prefs.lang].needconf_d }})&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->

<!--            <p class="grey">💡{{ txt[prefs.lang].change_commethod }} {{ txt[prefs.lang].can_changecom }}.</p>-->


<!--            <div v-if="subRes.ask_source" style="margin-top: 4em;">-->
<!--                <el-divider>{{ txt[prefs.lang].ask_source }}</el-divider>-->
<!--                <el-select v-model="source" @change.native="saveSource" :placeholder=txt[prefs.lang].ask_source>-->
<!--                    <el-option v-for="s in sources.filter(i => i.ask)" :value=s.id :label=s[prefs.lang]></el-option>-->
<!--                </el-select>-->
<!--            </div>-->

<!--            <p>🌿</p>-->

<!--        </div>-->

<!--    </template>-->


    <template v-else-if="step === 3">

        <div class="loader" v-if="placeOrderLoading" v-loading="placeOrderLoading"></div>

        <div v-if="subRes && subRes.hash" class="centered narrow distanced" style="max-width: 700px;">

            <h1>{{ txt[prefs.lang].thanks }}</h1>
            <h3>{{ txt[prefs.lang].your_order }} {{ txt[prefs.lang].placed }}</h3>

            <template v-if="subRes.card">

                <p v-if="!whaitavail">{{ txt[prefs.lang].all_avail }}</p>
                <p v-if="!whaitavail">{{ txt[prefs.lang].will_ship }}</p>

                <p class="grey">

                    <b>IBAN:</b> {{ subRes.card[0] }}
                        &nbsp;<el-button plain round icon="el-icon-document-copy" @click="copy(subRes.card[0])" size="mini"> {{ txt[prefs.lang].copy }}</el-button>
                    <br>
                    <b>{{ txt[prefs.lang].payment.i }}</b> {{ txt.uk.order }} {{ txt.uk.from }} {{ subRes.placed }}
                      <el-button plain round icon="el-icon-document-copy" @click="copy(txt.uk.order +' '+ txt.uk.from+' '+subRes.placed)" size="mini"></el-button>
                    <br>
                    <b>{{ txt[prefs.lang].to_pay }}:</b> {{ subRes.sum_to_pay }} грн<br>
                    <br>
                    <el-button plain round size="mini" icon="el-icon-document"
                         @click="requisites = subRes.card[1] + '\n' + txt[prefs.lang].payment.i+' '+txt.uk.order +' '+ txt.uk.from+' '+subRes.placed+'\n'+txt[prefs.lang].to_pay+': '+subRes.sum_to_pay+' грн'">{{ txt[prefs.lang].payment.full }}</el-button>

                    <br><br>💡 {{ txt[prefs.lang].mono_adv }}

                </p>

            </template>

            <p v-if="subRes.pick_up">{{ txt[prefs.lang].pick_up1 }} {{ subRes.pick_up }} {{ txt[prefs.lang].pick_up2 }}</p>
<!--            txt[prefs.lang].working_hours_p         -->
            <p v-if="subRes.pick_up" v-html="settings['working_hours_'+prefs.lang].split('<!--SEP-->')[1]"></p>

<!--            <p class="hidden-xs-only">-->
<!--                <el-tooltip :content=txt[prefs.lang].needconf_d placement="top">-->
<!--                    <el-checkbox v-model="needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>-->
<!--                </el-tooltip>-->
<!--            </p>-->
<!--            <p class="hidden-sm-and-up">-->
<!--                <el-checkbox v-model="needconfirm" :label=txt[prefs.lang].needconfirm></el-checkbox>-->
<!--                <br>({{ txt[prefs.lang].needconf_d }})-->
<!--            </p>-->

          <p class="grey">💡{{ txt[prefs.lang].change_commethod }} {{ txt[prefs.lang].can_changecom }}.</p>


                <div v-if="subRes.ask_source" style="margin-top: 4em;">
                    <el-divider>{{ txt[prefs.lang].ask_source }}</el-divider>
                    <el-select v-model="source" @change.native="saveSource" :placeholder=txt[prefs.lang].ask_source>
                        <el-option v-for="s in sources.filter(i => i.ask)" :value=s.id :label=s[prefs.lang]></el-option>
                    </el-select>
                </div>

            <p>🌿</p>

        </div>

    </template>


    <div v-if="step === 4" class="loader" v-loading="changeContactLoading"></div>


    <el-dialog v-model="requisites" :title=txt[prefs.lang].payment.full center>
        <el-input
            rows="8"
            type="textarea"
            :value=requisites
        />

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="requisites = false" plain round>{{ txt[prefs.lang].cancel }}</el-button>
                <el-button type="success" round icon="el-icon-document-copy" @click="copy(requisites)">{{ txt[prefs.lang].copy }}</el-button>
            </span>
        </template>
    </el-dialog>
    <!--    {{ fd.city }}-->

</template>



<script>
import {ref, reactive, watch, watchEffect, computed, onMounted, inject} from 'vue'
import useCart from "@/use/cart"
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import whsQuery from '../graphql/np_whs.query.gql'
import { txt } from '@/use/txt'
import {gql} from "@apollo/client"
import CheckoutItem from '@/components/CheckoutItem'
import CartDiscounts from '@/components/CartDiscounts'
import orderQuery from "../graphql/order.query.gql"
import order_Query from "../graphql/order_.query.gql"
import router from '@/router/index'
import productsQuery from '@/graphql/products.query.gql'
import {apolloClient} from "../main";
import axios from "axios"
import { log_action } from '@/use/cart'

export default {
    setup(props) {

        const { me, user, prefs, cart, products, sources, message, settings } = useCart()
        const baseUrl = inject('baseUrl')

        let whaitavail = router.currentRoute.value.query.whaitavail === 'true' ? true : false
        let guest = cart.q() && router.currentRoute.value.query.guest === 'true' && !me.value

        if ((!me.value && !user.value && !guest) || (!props.id && cart.isOver() && !whaitavail))
            router.push({ path: '/cart' })

        const ttl = prefs.lang == 'ru' ? ref('DescriptionRu') : ref('Description')
        watchEffect(function() {
            ttl.value = prefs.lang == 'ru' ? 'DescriptionRu' : 'Description'
        })
        const cities = ref(txt.cities)
        const whs = ref(null)
        const step = ref(1)
        const contact = ref(null)

        const fd = localStorage.getItem('fd') ? reactive(JSON.parse(localStorage.getItem('fd')))
            : reactive({
                firstname: null,
                lastname: null,
                middlename: null,
                phone: null,
                payment_method: null,
                delivery: null,
                delivery_type: "1",
                np_city: {
                    Ref: null
                },
                np_wh: {
                    Ref: null
                },
                // np_wh_ref: null,
                address: null,
                ukp_wh: null,
                // ukp_agree: null,
                comment: null,
                source_id: null,
                needconfirm: 0
            })

        // if order change mode
        const order = props.id ? useResult(useQuery(user.value ? order_Query : orderQuery, {id: props.id}).result, null, data => data.order) : null
        // if wrong order
        if (props.id && !order.value) {
            // message('!', txt[prefs.lang].errors[6], 'warning')
            router.push({ path: '/profile' })
        }

        function switchContact() {
            // console.log('switchContact')

            if (props.id && order.value || contact.value || (!localStorage.getItem('fd') && me.value && me.value.lastOrder)) {

                let lo = order && order.value ? order.value
                    : user.value && me.value && contact.value ? me.value.orders.find(i => i.contact_id == contact.value)
                        : me.value.lastOrder

                if (!lo && user.value && me.value && contact.value) {
                    let cont = me.value.contacts.find(i => i.id == contact.value)
                    fd.firstname = cont.firstname
                    fd.lastname = cont.lastname
                    fd.middlename = cont.middlename
                    fd.phone = cont.phone.number
                    return
                }

                fd.firstname = lo.contact.firstname
                fd.lastname = lo.contact.lastname
                fd.middlename = lo.contact.middlename
                fd.phone = lo.contact.phone.number
                fd.payment_method = lo.payment_method < 3 ? null : lo.payment_method.toString()
                fd.delivery = lo.delivery.toString()
                fd.delivery_type = lo.delivery_type ? lo.delivery_type.toString() : "1"
                fd.address = lo.address
                fd.ukp_wh = lo.ukp_wh
                fd.comment = props.id ? lo.comment : null
                fd.source_id = lo.source_id ? lo.source_id : null

                if (lo.np_city) {
                    fd.np_city = JSON.parse(JSON.stringify(lo.np_city))
                    cities.value = [lo.np_city]
                }

                if (lo.np_wh) {
                    fd.np_wh = JSON.parse(JSON.stringify(lo.np_wh))
                    whs.value = [lo.np_wh]
                }
            } else if (localStorage.getItem('fd')) {

                if (fd.np_city.Ref) {
                    cities.value = [fd.np_city]

                    if (fd.np_wh.Ref) {
                        whs.value = [fd.np_wh]
                    }
                    // else {
                    //     if (isSrcNp) getWH()
                    //     else getWHs('')
                    // }
                }
                // console.log(whs.value)
            }

        }

        switchContact()
        // const commethod = computed(() => me.value.socials.find(i => i.status === 2) || {source_id: 2})
        // watchEffect(() => {
        //     let social = me.value.socials.find(i => i.source_id == fd.commethod)
        //     fd.comcontact = social ? social.username : null
        // })

        watchEffect(() => {
            localStorage.setItem('fd', JSON.stringify(fd))
            // log_action({ value: JSON.stringify(fd) })
        })


        watchEffect(() => {
            log_action({ type: 6, value: step.value })
        })

        var rules = {
            firstname: [
                { required: true, message: txt[prefs.lang].required, trigger: 'change' },
                { pattern: /^[^\d,_*]+$/, message: txt[prefs.lang].wrong_symbols, trigger: 'change' },
                { min: 2, message: txt[prefs.lang].too_short, trigger: 'change' },
            ],
            lastname: [
                { required: true, message: txt[prefs.lang].required, trigger: 'change' },
                { pattern: /^[^\d,_*]+$/, message: txt[prefs.lang].wrong_symbols, trigger: 'change' },
                { min: 2, message: txt[prefs.lang].too_short, trigger: 'change' },
            ],
            middlename: [
                { required: fd.delivery_type == 2 ? true : false, message: txt[prefs.lang].required, trigger: 'change' },
                { pattern: /^[^\d,_*]+$/, message: txt[prefs.lang].wrong_symbols, trigger: 'change' },
                { min: 2, message: txt[prefs.lang].too_short, trigger: 'change' },
            ],
            phone: [
                { required: true, message: txt[prefs.lang].required, trigger: 'change' },
                { pattern: /^0?[^0]\d{8}$/, message: txt[prefs.lang].phone_format, trigger: 'change' },
            ],
            delivery: [
                { required: true, message: txt[prefs.lang].required, trigger: 'change' }
            ],
            payment_method: [
                { required: true, message: txt[prefs.lang].required, trigger: 'change' }
            ]
        }
        // console.log(rules.middlename[0].required)
        // watch(fd.delivery_type, () => {
        //     rules.middlename[0].required = fd.delivery_type == 2 ? true : false
        // })

        // const isFilled = true
        const isFilled = computed(() => {

            let wrong = 0

            if (!fd.firstname ||
                !fd.lastname ||
                !fd.phone ||
                !fd.payment_method ||
                fd.payment_method < 3 ||
                !fd.delivery) wrong++

            if (fd.delivery > 0) {
                if (!fd.np_city.Ref || fd.payment_method == 3) wrong++
                if (fd.delivery == 1 && fd.delivery_type == 1 && !fd.np_wh.Ref) wrong++
                if (fd.delivery == 2 && fd.delivery_type == 1 && !fd.ukp_wh) wrong++
                if (fd.delivery_type == 2 && !fd.address) wrong++
                if (fd.delivery_type == 2 && !fd.middlename) wrong++
                if (fd.delivery == 2 && fd.payment_method == 3) wrong++
            }

            return wrong ? false : true
        })


        const isSrcNp = computed(() => !txt.cities.some(i => i.Ref === fd.np_city.Ref))

        function getCities(city) {
            // console.log('getCities('+city+')')
            var opts = {
                "modelName": "Address",
                "calledMethod": "searchSettlements",
                "methodProperties": {
                    "CityName": city,
                    "Limit": 10
                }
            }

            var xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://api.novaposhta.ua/v2.0/json/', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(opts));

            xhr.onreadystatechange = function() {
                if (xhr.readyState != 4) return;

                if (xhr.status == 200) {
                    // console.log('got cities')
                    let d = JSON.parse(xhr.responseText)
                    if (d.data[0].Addresses !== void 0) cities.value = d.data[0].Addresses
                    // if (resp.message.success) location.reload(true);
                }
                else {
                    // console.log('XHR Error: '+xhr.thrownError)
                    // popup.innerHTML += xhr.status + ' ' + xhr.statusText + ': ' + xhr.thrownError;
                }
            }
        }

        onMounted(() => {
            // for mobile
            document.getElementById('city').addEventListener('keyup', e => {
                getCities(e.target.value)
            })

            document.querySelectorAll('input').forEach((i) => {
              i.addEventListener('blur', e => {
                let sfd = localStorage.getItem('sfd') ? JSON.parse(localStorage.getItem('sfd')) : null

                Object.entries(fd).forEach(i => {
                  if ((i[0] === 'np_city' || i[0] === 'np_wh')) {
                    if (i[1].Ref && (!sfd || sfd[i[0]].Ref != i[1].Ref))
                      // console.log(i[1].Ref)
                      log_action({ type: 8, value: i[0]+': '+i[1].Ref })
                  }

                  else if (i[1] && (!sfd || sfd[i[0]] != i[1])) {
                    // console.log(i)
                    log_action({ type: 8, value: i[0]+': '+i[1] })

                  }
                })
                localStorage.setItem('sfd', JSON.stringify(fd))
              })
            })
        })

        function onCityChange() {
            // console.log('onCityChange')
            whs.value = null
            fd.np_wh = {
                Ref: null
            }

            if (fd.np_city.Ref) {
                // fd.np_city = cities.value.find(i => i.Ref === fd.np_city.Ref)
                fd.np_city = JSON.parse(JSON.stringify(cities.value.find(i => i.Ref === fd.np_city.Ref)))
                if (fd.delivery == 1 && fd.delivery_type == 1 && isSrcNp) getWH()
            }
        }

        function getWH() {
            // console.log('getWH')
            var opts = {
                "modelName": "Address",
                "calledMethod": "getWarehouses",
                "methodProperties": {
                    "SettlementRef": fd.np_city.Ref,
                    "Limit": 100,
                    // "Language": "ru"
                }
            }

            var xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://api.novaposhta.ua/v2.0/json/', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(opts));
            // console.log(JSON.stringify(opts))

            xhr.onreadystatechange = function () {
                if (xhr.readyState != 4) return;

                if (xhr.status == 200) {
                    // console.log('got whs')
                    // console.log(JSON.parse(xhr.responseText))
                    whs.value = JSON.parse(xhr.responseText).data
                    // if (resp.message.success) location.reload(te);
                } else {
                    // console.log('XHR Error: ' + xhr.thrownError)
                    // popup.innerHTML += xhr.status + ' ' + xhr.statusText + ': ' + xhr.thrownError;
                }
            }

        }

        var t;

        function setWhTimer(query = ' ') {
            // console.log('setWhTimer')
            clearTimeout(t);
            t = setTimeout(getWHs, 500, query);
        }

        function onWhChange() {
            if (fd.np_wh.Ref) fd.np_wh = JSON.parse(JSON.stringify(whs.value.find(i => i.Ref === fd.np_wh.Ref)))
            if (isSrcNp) getWH()
            // else setWhTimer() // Плохо! Перезагружает список отделений.
        }

        function getWHs(query) {
            // console.log('getWHs')

            if (fd.np_city.Ref && fd.delivery == 1 && fd.delivery_type == 1) {

                const {result, error, loading} = useQuery(whsQuery,
                    {ref: fd.np_city.Ref, query: '%'+query.trim()+'%'})
                watchEffect(() => {
                    whs.value = result && result.value && result.value.np_city ? result.value.np_city.whs.data : null
                    if (result && result.value && !result.value.np_city) getWH()
                    // result && result.value ? console.log(result.value.np_city.whs.data) : ''
                })
                // if (result && result.value && !result.value.np_city) getWH()
            }
        }

        // let mutName = guest ? 'placeGuestOrder' : 'placeOrder' // `...${mutName}...`
        const { mutate: placeOrder, loading: placeOrderLoading, onError } = user.value ? useMutation(gql`
            mutation($fd: String!, $whaitavail: Boolean!, $items: String!, $total: Float!, $customer_id: ID!) {
                order: placeOrder_(fd: $fd, whaitavail: $whaitavail, items: $items, total: $total, customer_id: $customer_id)
            }`) : useMutation(gql`
            mutation($fd: String!, $whaitavail: Boolean!, $items: String!, $total: Float!, $guest: Boolean!) {
                order: placeOrder(fd: $fd, whaitavail: $whaitavail, items: $items, total: $total, guest: $guest)
            }`)

        const { mutate: changeContact, loading: changeContactLoading } = user.value ? useMutation(gql`
            mutation($id: ID!, $fd: String!) {
                contact: changeContact_(id: $id, fd: $fd)
            }`) : useMutation(gql`
            mutation($id: ID!, $fd: String!) {
                contact: changeContact(id: $id, fd: $fd)
            }`)


        // const { mutate: fakeOrder, loading: placeOrderLoading, onError } = useMutation(
        //     gql`mutation($fd: String!, $items: String!, $total: Float!) {
        //         fakeOrder(fd: $fd, items: $items, total: $total)
        //     }`)

        const subRes = ref(0)

        function onSubmit(stp) {

            window.scrollTo(0,0)

            step.value = stp

            // if (stp === 2) router.push({ path: 'checkout', query: { 'success': 1 } }) // test only

            if (stp === 3) {
                placeOrder({ fd: JSON.stringify(fd), whaitavail: whaitavail, items: JSON.stringify(cart.items), total: cart.total(), customer_id: me.value ? me.value.id : null, guest: guest })
                    .then(r => {
                        console.log(r.data.order)
                        subRes.value = JSON.parse(r.data.order.split(/\n\r?\n\r?/)[1])
                        console.log(subRes.value)

                        if (subRes.value.error && subRes.value.error < 3) {
                            apolloClient.query({ query: productsQuery, fetchPolicy: "network-only" }).then(r => {
                                products.value = r.data.products
                                message('!', txt[prefs.lang].errors[subRes.value.error], 'warning')
                                router.push({path: '/cart'})
                            })
                        }

                        else if (subRes.value.error && subRes.value.error >= 3) step.value = 1

                        // SUCCESSFUL order placement
                        else {
                            localStorage.removeItem('fd')
                            cart.emptyCart(false)
                            subRes.value.placed = subRes.value.placed.split('.')[0].replace(/T/, ' ')
                            message(txt[prefs.lang].thanks, txt[prefs.lang].order_placed, 'success')
                            history.replaceState('', null, guest ? '/products/all' : '/order/'+subRes.value.hash)
                            localStorage.setItem('n_bc', subRes.value.n_bc)
                        }
                    }).catch(e => {
                        axios.post('/api/error', { type: 3, error: e.toString() })
                        message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                        step.value = 1
                    })
            }

            else if (stp === 4 && props.id) {
                changeContact({id: props.id, fd: JSON.stringify(fd)}).then(r => {
                    subRes.value = JSON.parse(r.data.contact.split(/\n\r?\n\r?/)[1])
                    // console.log(subRes.value)
                    if (subRes.value.error && subRes.value.error < 6) step.value = 1
                    else if (subRes.value.error && subRes.value.error == 6) {
                        message('!', txt[prefs.lang].errors[subRes.value.error], 'warning')
                        router.push({path: '/order/'+props.id})
                    }
                    else {
                        localStorage.removeItem('fd')
                        message(txt[prefs.lang].saved, txt[prefs.lang].all_saved, 'success')
                        router.push({path: '/order/'+subRes.value.hash})
                    }
                    }).catch(e => {
                        axios.post('/api/error', {type: 4, error: e.toString()})
                        message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
                        step.value = 1
                })
            }

        }

        onError(e => { console.log(e) })

        function copy(v) {
            navigator.clipboard.writeText(v).then(function() {
                message(txt[prefs.lang].copied, v, 'success')
            })
        }

        // const needconfirm = ref(false)
        //
        // const { mutate: needConfirm, loading: needConfirmLoading } = user.value ? useMutation(gql`
        //     mutation($id: ID!, $needconfirm: Boolean!) {
        //         needconfirm: needConfirm_(id: $id, needconfirm: $needconfirm) {
        //             id
        //             needconfirm
        //         }
        //     }`) : useMutation(gql`
        //     mutation($id: ID!, $needconfirm: Boolean!) {
        //         needconfirm: needConfirm(id: $id, needconfirm: $needconfirm) {
        //             id
        //             needconfirm
        //         }
        //     }`)
        //
        // watch(needconfirm, () => {
        //     needConfirm({ id: subRes.value.hash, needconfirm: needconfirm.value }).then(r => {
        //         if (!r.data.needconfirm) message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
        //         else if (r.data.needconfirm.needconfirm) message('Ok!', txt[prefs.lang].wellcontact, 'success')
        //         else message('Ok!', txt[prefs.lang].wontcontact, 'info')
        //     }).catch(e => {
        //         message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
        //     })
        // })


        const source = ref(null)

        const { mutate: save_source } = user.value ? useMutation(gql`
            mutation($source_id: ID!, $id: ID!) {
                src: saveSource_(source_id: $source_id, id: $id) {
                    source_id
                }
            }
        `) : useMutation(gql`
            mutation($source_id: ID!) {
                src: saveSource(source_id: $source_id) {
                    source_id
                }
            }
        `)

        // customer source
        function saveSource() {
            save_source({ source_id: source.value, id: me.value.id }).then(r => {
                if (r.data.src.source_id) message(txt[prefs.lang].thanks, txt[prefs.lang].all_saved, 'success')
                else message(txt[prefs.lang].thanks, txt[prefs.lang].all_saved, 'info')
            }).catch(e => {
                message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
            })
        }

        function cleanFd() {
            localStorage.removeItem('fd')
            router.go()
        }

        const requisites = ref(false)

        subRes.value = {
            "hash": "06fe01744a9ea11fe65d1",
            "sum_to_pay": 89,
            "placed": "2024-02-17 22:16:03",
            "card": [
                "UA373220010000026006310026160",
                "Одержувач: ФОП Флауер Олександр Борисович\nIBAN: UA373220010000026006310026160\nЄДРПОУ: 3277418252\nАТ «УНІВЕРСАЛ БАНК»\nМФО: 322001\nОКПО: 21133352"
            ],
            "pick_up": "2024-02-20",
            "ask_source": true,
            "n_bc": false
        }

        return { fd, me, user, getCities, getWH, getWHs, onCityChange, setWhTimer, onWhChange, cities, whs, prefs, ttl, isFilled, onSubmit, txt, cart, products, placeOrderLoading, changeContactLoading, subRes, step, rules, copy, props, source, sources, saveSource, contact, cleanFd, switchContact, isSrcNp, requisites, settings, whaitavail }
        // needconfirm
    },

    props: {
        id: [String, Number]
    },
    components: {
        CheckoutItem,
        CartDiscounts
    }
}
</script>

<style scoped>
    button {
        text-align: center;
    }
    .checkout_form {
        max-width: 460px;
        margin: 0 auto;
        /*text-align: center;*/
    }
    /*.checkout_form .el-button {*/
    /*    text-align: center !important;*/
    /*}*/
    .grey {
        padding: 1.5em 2em;
        background-color: #fafafa;
    }

    .distanced p {
        margin-top: 2em;
    }
    /*.el-input-group__prepend {*/
    /*    margin-right: 0px !important;*/
    /*    padding-right: 0px !important;*/
    /*}*/
    .el-form-input__inner {
        /*min-width: 100% !important;*/
    }
    /*input {*/
    /*    width: 100% !important;*/
    /*}*/
    .el-input {
        width: 320px;
        /*min-width: 100% !important;*/
    }
    .el-form-item__content {
        /*width: 320px;*/
        /*width: 100% !important;*/
        /*display: block !important;*/
    }

    /*.el-form-input {*/
    /*    width: 100% !important;*/
    /*}*/
    .el-form-item {
        /*width: 400px;*/
        display: block !important;
    }
    .el-form-item__label {
        color: #aaa !important;
        /*width: 100% !important;*/
        /*width: 160px !important;*/
        /*clear: both !important;*/
        /*display: block !important;*/
    }
    .el-select {
        /*width: 100% !important;*/
        width: 320px;
    }
    /*.input-with-select .el-input-group__prepend {*/
    /*    background-color: #fff;*/
    /*}*/
    .el-input__inner {
        letter-spacing: 0.2em !important;
    }
    .hidden {
        display: none !important;
    }
</style>